
<template>
  <div id="box">
    <h1>产后抑郁量表问卷</h1>
    <p>请根据您过去七天的感受，选择最能反应您实际情况的答案。</p>
    <div>
      <h4>1.我能够开心大笑，并看到事情有趣的一面</h4>
      <el-radio-group v-model="radio">
        <el-radio label="0">A：同以前一样</el-radio><br />
        <el-radio label="1">B：沒有以前那麼多 </el-radio><br />
        <el-radio label="2">C：肯定比以前少 </el-radio><br />
        <el-radio label="3">D: 完全不能</el-radio><br /> </el-radio-group
      ><br />
      <h4>2.我欣然期待未来的一切</h4>
      <el-radio-group v-model="radio1">
        <el-radio label="0">A：同以前一样</el-radio><br />
        <el-radio label="1">B：沒有以前那麼多 </el-radio><br />
        <el-radio label="2">C：肯定比以前少 </el-radio><br />
        <el-radio label="3">D: 完全不能</el-radio><br /> </el-radio-group
      ><br />
      <br />
      <h4>3.当事情出错时，我会不必要地責备自己</h4>
      <el-radio-group v-model="radio2">
        <el-radio label="0">A：不会这样</el-radio><br />
        <el-radio label="1">B：不经常这样 </el-radio><br />
        <el-radio label="2">C：有时会这样 </el-radio><br />
        <el-radio label="3">D: 大部分时候会这样</el-radio><br /></el-radio-group
      ><br />
      <br />
      <h4>4.我无缘无故感到焦虑和担心</h4>
      <el-radio-group v-model="radio3">
        <el-radio label="0">A：一点也没有</el-radio><br />
        <el-radio label="1">B：极少有 </el-radio><br />
        <el-radio label="2">C：有时候这样 </el-radio><br />
        <el-radio label="3">D: 经常这样</el-radio><br /></el-radio-group
      ><br />
      <br />
    </div>
    <br />
    <h4>5.我无缘无故感到害怕和惊慌</h4>
    <el-radio-group v-model="radio4">
      <el-radio label="0">A：一点也没有</el-radio><br />
      <el-radio label="1">B：极少有 </el-radio><br />
      <el-radio label="2">C：有时候这样 </el-radio><br />
      <el-radio label="3">D: 经常这样</el-radio><br /></el-radio-group
    ><br />
    <h4>6.当很多事情冲着我而来，使我透不过气</h4>
    <el-radio-group v-model="radio5">
      <el-radio label="0">A：一直都能应付得很好</el-radio><br />
      <el-radio label="1">B：大部分時时候我可以应付自如</el-radio><br />
      <el-radio label="2">C：有时候我不能像平时那样应付得好 </el-radio><br />
      <el-radio label="3">D: 大多数时候我都不能应付</el-radio
      ><br /></el-radio-group
    ><br />
    <h4>7.我因心情不好而影响睡眠</h4>
    <el-radio-group v-model="radio6">
      <el-radio label="0">A：沒有这样</el-radio><br />
      <el-radio label="1">B：不经常这样 </el-radio><br />
      <el-radio label="2">C：有时候这样 </el-radio><br />
      <el-radio label="3">D: 大部分时候这样</el-radio><br /></el-radio-group
    ><br />
    <h4>8.我感到难过或悲伤</h4>
    <el-radio-group v-model="radio7">
      <el-radio label="0">A：沒有这样</el-radio><br />
      <el-radio label="1">B：不经常这样 </el-radio><br />
      <el-radio label="2">C：颇经常这样 </el-radio><br />
      <el-radio label="3">D: 大部分时候这样</el-radio><br /></el-radio-group
    ><br />
    <h4>9.我很难受，想哭</h4>
    <el-radio-group v-model="radio8">
      <el-radio label="0">A：沒有这样</el-radio><br />
      <el-radio label="1">B：只是有时会这样 </el-radio><br />
      <el-radio label="2">C：颇经常这样 </el-radio><br />
      <el-radio label="3">D: 大部分时候这样</el-radio><br /></el-radio-group
    ><br />
    <h4>10.我有过想要伤害自己的想法</h4>
    <el-radio-group v-model="radio9">
      <el-radio label="0">A：从来没有</el-radio><br />
      <el-radio label="1">B：很少这样 </el-radio><br />
      <el-radio label="2">C：有时候这样</el-radio><br />
      <el-radio label="3">D: 颇经常这样</el-radio><br /></el-radio-group
    ><br />
    <el-button type="success" @click="ExamSolutionADD" :disabled="isdisabled"
      >提交</el-button
    >
  </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
  mounted() {
    this.get();
  },
  updated() {
    // console.log("总分：", this.total);
    // console.log("说明", this.explain);
  },
  methods: {
    async ExamSolutionADD() {
      const data = await axios({
        url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=产后抑郁量表EPDS&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
        method: "post",
        data,
      });
      if (data.data.data == true) {
        this.isShow = !this.isShow;
        Message.success("提交成功");
        this.$router.push("/Assessment");
      }
    },
    //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
    get() {
      const patientN = localStorage.getItem("patientN");
      const patientI = localStorage.getItem("patientI");
      this.name = JSON.parse(patientN);
      this.cid = JSON.parse(patientI);
    },
  },
  computed: {
    isdisabled() {
      if (
        this.radio == "" ||
        this.radio1 == "" ||
        this.radio2 == "" ||
        this.radio3 == "" ||
        this.radio4 == "" ||
        this.radio5 == "" ||
        this.radio6 == "" ||
        this.radio7 == "" ||
        this.radio8 == "" ||
        this.radio9 == ""
      ) {
        return true;
      }
      if (
        this.radio != "" ||
        this.radio1 != "" ||
        this.radio2 != "" ||
        this.radio3 != "" ||
        this.radio4 != "" ||
        this.radio5 != "" ||
        this.radio6 != "" ||
        this.radio7 != "" ||
        this.radio8 != "" ||
        this.radio9 != ""
      ) {
        return false;
      }
    },
    explain() {
      if (this.total < 9) {
        return "正常";
      }
      if (this.total >= 9) {
        return "可能患有产后抑郁症";
      }
    },
    total() {
      return (
        this.radio * 1 +
        this.radio1 * 1 +
        this.radio2 * 1 +
        this.radio3 * 1 +
        this.radio4 * 1 +
        this.radio5 * 1 +
        this.radio6 * 1 +
        this.radio7 * 1 +
        this.radio8 * 1 +
        this.radio9 * 1
      );
    },
  },
  data() {
    return {
      ETID: "E4",
      isShow: false,
      name: "",
      cid: "",
      radio: "",
      radio1: "",
      radio2: "",
      radio3: "",
      radio4: "",
      radio5: "",
      radio6: "",
      radio7: "",
      radio8: "",
      radio9: "",
    };
  },
};
</script>

<style lang='scss' scoped>
// @import "./style.scss";
.el-radio {
  margin: 10px;
}
body {
  background: rgb(220, 245, 179);
}
#box {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-left: 30px;
  // background: rgb(206, 245, 175);
}
.label_color {
  color: rgb(202, 44, 207);
}
h1 {
  text-align: center;
}

h4 {
  color: rgb(140, 102, 245);
}
</style>

